<template>
  <AppLayout>
    <main>
      <div
        class="page-header pb-10 page-header-dark bg-gradient-primary-to-secondary"
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>New User</span>
            </h1>
            <div class="page-header-subtitle ml-2">Create New User</div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">New User</div>
          <div class="card-body">
            <div class="form">
              <form @submit.prevent="handleSubmit()">
                <div class="form-group">
                  <label for="role">Select Role</label
                  ><select
                    v-model="data.role"
                    class="form-control"
                    id="role"
                    @change="reset()"
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="Admin">Admin</option>
                    <option value="Broker">Broker</option>
                    <!-- <option value="Agent">Agent</option>
                    <option value="Client">Client</option> -->
                  </select>
                </div>

                <div v-if="data.role == 'Broker'">
                  <div class="form-group">
                    <label for="">Select Broker</label
                    ><v-select
                      v-model="broker"
                      class="form-select"
                      label="name"
                      :options="paginated"
                      :filterable="false"
                      @search="onSearch"
                    >
                      <template #search="{ attributes, events }">
                        <span class="mt-1 mx-3 py-1" v-if="broker === null">
                          Search Broker
                        </span>

                        <input
                          class="vs__search"
                          :required="data.party !== '' ? false : true"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                    </v-select>
                  </div>

                  <div class="form-group">
                    <label for="name">Full Name</label
                    ><input
                      class="form-control"
                      id="name"
                      type="text"
                      v-model="data.name"
                      placeholder="Enter Full Name"
                      disabled
                    />
                  </div>

                  <div class="form-group">
                    <label for="emailAddress">Email address</label
                    ><input
                      class="form-control"
                      id="emailAddress"
                      type="email"
                      v-model="data.email"
                      placeholder="name@example.com"
                      disabled
                    />
                  </div>

                  <div class="form-group">
                    <label for="number">Phone Number</label
                    ><input
                      class="form-control"
                      id="number"
                      type="text"
                      v-model="data.phoneNumber"
                      placeholder="Enter Phone Number"
                      disabled
                    />
                  </div>
                </div>

                <div v-else-if="data.role == 'Admin'">
                  <div class="form-group">
                    <label for="name">Full Name</label
                    ><input
                      class="form-control"
                      id="name"
                      type="text"
                      v-model="data.name"
                      placeholder="Enter Full Name"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="emailAddress">Email address</label
                    ><input
                      class="form-control"
                      id="emailAddress"
                      type="email"
                      v-model="data.email"
                      placeholder="name@example.com"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="number">Phone Number</label
                    ><input
                      class="form-control"
                      id="number"
                      type="text"
                      v-model="data.phoneNumber"
                      placeholder="Enter Phone Number"
                      required
                    />
                  </div>
                </div>

                <div v-if="data.role == 'Broker'" class="form-group">
                  <label for="">Select Marketing Staff</label>
                  <v-select
                    v-model="data.mktStaff"
                    class="form-select"
                    multiple
                    label="name"
                    :options="marketers"
                  >
                    <template #search="{ attributes, events }">
                      <span
                        class="mt-1 mx-3 py-1"
                        v-if="data.mktStaff.length == 0"
                      >
                        Select marketing Staff
                      </span>
                      <input
                        class="vs__search"
                        :required="data.mktStaff !== '' ? false : true"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>

                <div class="form-group">
                  <label for="password">Password</label
                  ><input
                    class="form-control"
                    id="password"
                    type="password"
                    v-model="data.password"
                    placeholder="Enter Password"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="cpassword">Password</label
                  ><input
                    class="form-control"
                    id="cpassword"
                    type="password"
                    v-model="data.confirmPassword"
                    placeholder="Confirm Password"
                    required
                  />
                </div>

                <button v-if="loading" class="btn btn-primary disabled">
                  <div
                    class="spinner-border spinner-border-sm mx-1 text-light"
                    role="status"
                  ></div>
                  Loading...
                </button>
                <button v-else type="submit" class="btn btn-primary">
                  Create {{ data.role }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";
import { useMarineStore } from "@/stores/marine";

export default {
  name: "New User",
  components: { AppLayout },
  data() {
    return {
      marketeer: null,
      broker: null,
      search: "",
      offset: 0,
      limit: 100,
      data: {
        id: "",
        name: "",
        email: "",
        phoneNumber: "",
        role: "",
        password: "",
        confirmPassword: "",
        mktStaff: [],
      },
    };
  },
  computed: {
    ...mapState(useAdminStore, ["loading", "brokers", "allBrokers"]),
    ...mapState(useMarineStore, ["marketers"]),

    filtered() {
      return this.allBrokers.filter((broker) =>
        broker.name
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase())
      );
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset);
    },
  },

  methods: {
    ...mapActions(useAdminStore, [
      "createUser",

      "getAllUsers",
      "success",
      "getAllBrokers",
    ]),
    ...mapActions(useMarineStore, ["getMarketers"]),
    reset() {
      this.broker = null;
      this.data = {
        ...this.data,
        ...{
          name: "",
          email: "",
          phoneNumber: "",

          password: "",
          confirmPassword: "",
          mktStaff: [],
        },
      };
    },

    onSearch(query) {
      this.search = query;
      this.offset = 0;
    },

    addBroker(broker) {
      if (this.broker !== null) {
        this.data.name = broker.name;
        this.data.email = broker.email;
        this.data.phoneNumber = broker.phoneNumber;
        this.data.id = broker.id;
      }
    },

    async handleSubmit() {
      if (this.data.password === this.data.confirmPassword) {
        const res = await this.createUser(this.data);
        if (res == "success") {
          this.$router.push("/users");
        }
      } else alert("Passwords Don't Match");
    },
  },

  watch: {
    broker: function () {
      if (this.broker !== null) {
        this.addBroker(this.broker);
      }
    },
  },

  created() {
    this.getAllUsers();
    this.getAllBrokers();
    this.getMarketers();
  },
};
</script>
